import { equalTo, get, orderByChild, query } from 'firebase/database';
import { logger } from '../../../logging';
import { ref } from '../../../utils/firebase';

export const validateBrazePartyId = async (
  party_id,
  party_name,
  id,
  setFieldError
) => {
  logger.debug(
    `[braze/validation] validateBrazePartyId - party_id: ${party_id}, party_name: ${party_name}, id: ${id}, typeof id: ${typeof id}`
  );

  const errorMessage = 'A Party ID must be provided and be unique';

  if (!party_name || party_name === '') {
    logger.debug(
      '[braze/validation] validateBrazePartyId  - there is no value, so we dont lookup. Returning as this is the cause of a setstate error'
    );
    setFieldError('party_id', errorMessage);
    return false; // there's no value so it is invalid
  }

  const accountsRef = ref('/accounts/braze');
  const accountsQuery = query(
    accountsRef,
    orderByChild('party_id'),
    equalTo(parseInt(party_id, 10))
  );
  const accountsSnap = await get(accountsQuery);

  const accounts = [];
  accountsSnap.forEach((v) => {
    accounts.push({ ...v.val(), id: v.key });
  });

  // so obviously there's an account with this party_id (if we're editing)
  if (accounts.length > 0) {
    const account = accounts[0];
    logger.debug(
      `[braze/validation] validateBrazePartyId  - accounts[0].id (${account.id}) and id (${id})`
    );
    // if there is an account with this party_id but a different uid, then we have a validation error
    if (account.id !== id) {
      logger.debug(
        `[braze/validation] validateBrazePartyId  - account.id (${account.id}) and id (${id}) are not the same`
      );
      setFieldError('party_id', errorMessage);
      return false;
    }
  }

  return true;
};

export const validateBrazeAccountName = async (
  account_name,
  id,
  setFieldError,
  setHasFormErrors
) => {
  logger.debug(
    `[BrazeFields] validateBrazeAccountName - account_name: ${account_name}, id: ${id}, typeof id: ${typeof id}`
  );

  const errorMessage = 'An account name must be provided and be unique';
  const value = account_name ? account_name.trim() : '';

  if (!value || value === '') {
    logger.debug(
      '[BrazeFields] validateBrazeAccountName - there is no value, so we dont lookup. Returning as this is the cause of a setstate error'
    );
    setFieldError('name', errorMessage);
    return false; // there's no value so it is invalid
  }

  const accountsRef = ref('accounts/braze');
  const accountsQuery = query(
    accountsRef,
    orderByChild('account_name'),
    equalTo(value)
  );
  const accountsSnap = await get(accountsQuery);

  const accounts = [];
  accountsSnap.forEach((v) => {
    accounts.push({ ...v.val(), id: v.key });
  });

  // so obviously there's an account with this name (if we're editing)
  if (accounts.length > 0) {
    const account = accounts[0];
    // if there is an account with this name but a different uid, then we have a validation error
    if (account.id !== id) {
      logger.debug(
        `[BrazeFields] validateBrazeAccountName - account.id (${account.id}) and id (${id}) are not the same`
      );
      setFieldError('account_name', errorMessage);
      return false;
    }
  }

  return true;
};

// account id should be unique
export const validateAccountId = async (rawValue, key, setFieldError) => {
  const errorMessage = 'An account ID must be provided and be unique';

  const value = rawValue ? rawValue.trim() : '';

  if (!value || value === '') {
    logger.debug(
      '[BrazeFields] [validateAccountId] there is no value, so we dont lookup. Returning as this is the cause of a setstate error'
    );
    setFieldError('account_id', errorMessage);
    return false; // there's no value so it is invalid
  }

  const accountsRef = ref('accounts/braze');
  const accountsQuery = query(
    accountsRef,
    orderByChild('account_id'),
    equalTo(value)
  );
  const accountsSnap = await get(accountsQuery);

  const accounts = [];
  accountsSnap.forEach((v) => {
    accounts.push({ ...v.val(), key: v.key });
  });

  // so obviously there's an account with this name (if we're editing)
  if (accounts.length > 0) {
    const account = accounts[0];
    // if there is an account with this name but a different uid, then we have a validation error
    if (account.key !== key) {
      logger.debug(
        `[BrazeFields] [validateAccountId] account.key (${account.key}) and key (${key}) are not the same`
      );
      setFieldError('account_id', errorMessage);
      return false;
    }
  }

  return true;
};

export const checkIfBrazeCredsExistForAccountId = async (
  account_id: string
): Promise<boolean | null> => {
  logger.debug(
    `[checkIfBrazeCredsExistForAccountId] called with account_id: ${account_id}`
  );

  try {
    const credsRef = ref(`/credentials/${account_id}`);
    const credsSnap = await get(credsRef);
    const creds = [];
    credsSnap.forEach((v) => {
      creds.push({ ...v.val() });
    });

    if (creds.length > 0) {
      logger.debug(
        `[checkIfBrazeCredsExistForAccountId] creds.length: ${
          creds.length
        }, creds: ${JSON.stringify(creds)}`
      );
      for (const cred of creds) {
        if (cred.active === true) {
          // exit if any cred active
          return true;
        }
      }
    }
  } catch (e) {
    return null;
  }

  return false;
};

export const checkDataAcquisitionValidForAccountId = async (
  account_id: string
): Promise<boolean | null> => {
  logger.debug(
    `[checkDataAcquisitionValidForAccountId] called with account_id: ${account_id}`
  );

  try {
    const accountRef = ref(`/accounts/braze/${account_id}`);
    const accountSnap = await get(accountRef);
    if (!accountSnap.exists()) {
      return false;
    }

    const account = accountSnap.val();

    logger.debug(
      `[checkDataAcquisitionValidForAccountId] account: ${JSON.stringify(
        account
      )}`
    );

    if (account.data_acquisition_selection) {
      // exit if any data_acquisition_selection value
      return true;
    }
  } catch (e) {
    return null;
  }

  return false;
};
