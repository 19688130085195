import React, { useContext, useState } from 'react';
import { Badge, Button, Col, Input, Label, Row } from 'reactstrap';
import { ref } from '../../../utils/firebase';
import * as R from 'ramda';
import { logger } from '../../../logging';
import { AbilityContext } from '../../../auth/Can';
import { update } from 'firebase/database';
import { useObjectVal } from 'react-firebase-hooks/database';
import { Loader } from '../../../components/Loader';

const brazeStyles = require('./braze.css');

export const BrazeSMSCountryAllowListRow = ({ account, setTabSaving }) => {
  const [saving, setSaving] = useState(false);
  const [clearing, setClearing] = useState(false);
  const [smsAllowlist, setSMSAllowlist] = useState(
    (account && account.sms_allow_list) || []
  );
  const [country, setCountry] = useState('');
  const [countries, countriesLoading] = useObjectVal(
    ref('countries') as any
  ) as any;

  const ability = useContext(AbilityContext);

  if (countriesLoading || !countries) {
    return <Loader loading={true} />;
  }

  const countryCodes = Object.keys(countries);
  const filteredCountryCodes = countryCodes.filter(
    (countryCode) => !smsAllowlist.includes(countryCode)
  );

  const countryOptions = R.map((countryCode) => {
    return (
      <option value={countryCode} key={countryCode}>
        {countries[countryCode].label}
      </option>
    );
  }, filteredCountryCodes);

  const allowed_countries = R.map((countryCode) => {
    return (
      <Badge
        pill
        color="info"
        className={brazeStyles.country_pill}
        key={countryCode}
      >
        {countries[countryCode].label}
        <Button close onClick={() => handleClearCountry(countryCode)} />
      </Badge>
    );
  }, smsAllowlist);

  logger.debug(
    `[BrazeSMSCountryAllowListRow] countries: ${JSON.stringify(countries)}`
  );

  const addCountry = async () => {
    setSaving(true);
    setTabSaving(true);

    try {
      const updatedList = smsAllowlist.includes(country)
        ? smsAllowlist
        : [...smsAllowlist, country].sort();

      logger.debug(
        `[BrazeSMSCountryAllowListRow][addCountry] updatedList: ${JSON.stringify(
          updatedList
        )}`
      );

      // now save against the account.
      const accountSMSAllowListRef = ref(`accounts/braze/${account.key}`);
      const fieldToUpdate = {};
      fieldToUpdate['sms_allow_list'] = updatedList;
      await update(accountSMSAllowListRef, fieldToUpdate);
      setSMSAllowlist(updatedList);
    } catch (e) {
      logger.error(
        `[BrazeSMSCountryAllowListRow][addCountry] error adding Country: ${e}`
      );
    }

    setSaving(false);
    setTabSaving(false);
  };

  const addAllRemainingCountries = async () => {
    setSaving(true);
    setTabSaving(true);

    try {
      const remainingCountries = Object.keys(countries).filter(
        (countryCode) => !smsAllowlist.includes(countryCode)
      );

      if (remainingCountries.length === 0) {
        setSaving(false);
        setTabSaving(false);
        return;
      }

      const updatedList = [...smsAllowlist, ...remainingCountries].sort();

      logger.debug(
        `[BrazeSMSCountryAllowListRow][addAllRemainingCountries] updatedList: ${JSON.stringify(
          updatedList
        )}`
      );

      // now save against the account.
      const accountSMSAllowListRef = ref(`accounts/braze/${account.key}`);
      const fieldToUpdate = {};
      fieldToUpdate['sms_allow_list'] = updatedList;
      await update(accountSMSAllowListRef, fieldToUpdate);
      setSMSAllowlist(updatedList);
    } catch (e) {
      logger.error(
        `[BrazeSMSCountryAllowListRow][addAllRemainingCountries] error adding Country: ${e}`
      );
    }

    setSaving(false);
    setTabSaving(false);
  };

  const handleClearCountry = async (countryCode) => {
    setClearing(true);
    setTabSaving(true);

    try {
      const updatedList = smsAllowlist.filter((code) => code !== countryCode);

      logger.debug(
        `[BrazeSMSCountryAllowListRow][handleClearCountry] updatedList: ${JSON.stringify(
          updatedList
        )}`
      );

      // now save against the account.
      const accountSMSAllowListRef = ref(`accounts/braze/${account.key}`);
      const fieldToUpdate = {};
      fieldToUpdate['sms_allow_list'] = updatedList;
      await update(accountSMSAllowListRef, fieldToUpdate);
      setSMSAllowlist(updatedList);
    } catch (e) {
      logger.error(
        `[BrazeSMSCountryAllowListRow][handleClearCountry] error adding Country: ${e}`
      );
    }

    setClearing(false);
    setTabSaving(false);
  };

  const clearAllCountries = async () => {
    setClearing(true);
    setTabSaving(true);

    try {
      const updatedList = [];

      logger.debug(
        `[BrazeSMSCountryAllowListRow][clearAllCountries] updatedList: ${JSON.stringify(
          updatedList
        )}`
      );

      // now save against the account.
      const accountSMSAllowListRef = ref(`accounts/braze/${account.key}`);
      const fieldToUpdate = {};
      fieldToUpdate['sms_allow_list'] = updatedList;
      await update(accountSMSAllowListRef, fieldToUpdate);
      setSMSAllowlist(updatedList);
    } catch (e) {
      logger.error(
        `[BrazeSMSCountryAllowListRow][clearAllCountries] error adding Country: ${e}`
      );
    }

    setCountry('');
    setClearing(false);
    setTabSaving(false);
  };

  const addCountryDisabled = !country || country === '';

  return (
    <Row className={brazeStyles.smsCountryAllowListRow}>
      <Col sm={2}>
        <Label for={`sms_country_allow_list`}>SMS Country Allow List</Label>
        <Input
          id={`sms_country_allow_list`}
          type="select"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          bsSize="sm"
        >
          <option value="" key="">
            Please Select Country
          </option>
          {countryOptions}
        </Input>
      </Col>
      <Col sm={1}>
        <Button
          color="primary"
          className={brazeStyles.countrySaveButton}
          onClick={(e) => addCountry()}
          disabled={
            ability.cannot('manage', 'braze_optins') ||
            saving ||
            addCountryDisabled
          }
          size="sm"
        >
          {saving ? 'Adding Country' : 'Add Country'}
        </Button>
      </Col>
      <Col sm={7}>{allowed_countries}</Col>
      <Col sm={1}>
        <Button
          color="primary"
          className={brazeStyles.allCountriesAddButton}
          onClick={(e) => addAllRemainingCountries()}
          disabled={ability.cannot('manage', 'braze_optins') || saving}
          size="sm"
        >
          {saving ? 'Adding All Countries' : 'Add All Countries'}
        </Button>
        <Button
          color="primary"
          className={brazeStyles.allCountriesClearButton}
          onClick={(e) => clearAllCountries()}
          disabled={ability.cannot('manage', 'braze_optins') || clearing}
          size="sm"
        >
          {clearing ? 'Clearing All Countries' : 'Clear All Countries'}
        </Button>
      </Col>
    </Row>
  );
};
