import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

import * as R from 'ramda';
import { BrazeOptinRow } from './BrazeOptinRow';

const brazeStyles = require('./braze.css');

export const BrazeSubscriptionGroupOptins = ({
  account,
  enumerations,
  setTabSaving,
  handleOptinActiveChange,
}) => {
  const channels = enumerations.enumerations.brazeSubscriptionGroupChannels;
  const channelsCodes = Object.keys(channels);
  const subscriptionGroupRows = R.map((channelCode) => {
    const channel = channels[channelCode];
    return (
      <BrazeOptinRow
        channel={channel}
        account={account}
        enumerations={enumerations}
        setTabSaving={setTabSaving}
        showSubscriptionGroup={true}
        key={channel.value}
        handleOptinActiveChange={handleOptinActiveChange}
      />
    );
  }, channelsCodes);

  return (
    <React.Fragment>
      <Row className={brazeStyles.optinEnabledRowHeader}>
        <Col sm={10}>
          <h4>Subscription Group Optins</h4>
        </Col>
      </Row>
      {subscriptionGroupRows}
    </React.Fragment>
  );
};
