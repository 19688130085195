import React from 'react';
import { StepperBar } from '../../../components/StepperBar';
import { Col, Row } from 'reactstrap';
import { logger } from '../../../logging';
import {
  BRAZE_OPTINS_TAB,
  CREDENTIALS_TAB,
  DATA_ACQUISITION_TAB,
  FIELDS_TAB,
} from '../AccountForm';

const brazeStyles = require('./braze.css');

export const BrazeAccountStepper = ({
  account,
  toggle,
  accountHasCreds,
  accountHasOptins,
}) => {
  const hasAccountKey = account && account.key ? true : false;
  const hasCredential = accountHasCreds;
  const hasDataAcqSelection =
    account &&
    account.data_acquisition_selection &&
    account.data_acquisition_selection !== '' &&
    account.data_acquisition_selection !== 'unselected'
      ? true
      : false;
  const hasOptin = accountHasOptins;
  const accountIsActive = account && account.active === true ? true : false;

  logger.debug(
    `[BrazeAccountStepper] account: ${JSON.stringify(
      account
    )}, accountHasCreds: ${accountHasCreds}
    typeof hasAccountKey: ${typeof hasAccountKey}
    typeof hasCredential: ${typeof hasCredential}
    typeof hasDataAcqSelection: ${typeof hasDataAcqSelection} (${
      account && account.data_acquisition_selection
    })
    typeof hasOptin: ${typeof hasOptin}`
  );

  const brazeSteps = [
    {
      step: 0,
      label: 'Create Account',
      tab: FIELDS_TAB,
      complete: hasAccountKey,
    },
    {
      step: 1,
      label: 'Save Credentials',
      tab: CREDENTIALS_TAB,
      complete: hasCredential,
      disabled: !hasAccountKey,
    },
    {
      step: 2,
      label: 'Select Data Acqusition',
      tab: DATA_ACQUISITION_TAB,
      complete: hasDataAcqSelection,
      disabled: !hasAccountKey,
    },
    {
      step: 3,
      label: 'Activate Account',
      tab: FIELDS_TAB,
      complete: accountIsActive,
      disabled: !hasAccountKey || !hasCredential,
    },
    {
      step: 4,
      label: 'Generate Optins',
      tab: BRAZE_OPTINS_TAB,
      complete: hasOptin,
      disabled: !hasAccountKey,
    },
  ];

  return (
    <Row className={brazeStyles.stepperRow}>
      <Col sm={6}>
        <StepperBar steps={brazeSteps} toggle={toggle} />
      </Col>
    </Row>
  );
};
